.none {
	color: var(--none-text);
	background: var(--none-paper);
	border-color: var(--none-border);
}

.default {
	color: var(--default-text);
	background: var(--default-paper);
	border-color: var(--default-border);
}

.primary {
	color: var(--primary-text);
	background: var(--primary-paper);
	border-color: var(--primary-border);
}

.secondary {
	color: var(--secondary-text);
	background: var(--secondary-paper);
	border-color: var(--secondary-border);
}

.tertiary {
	color: var(--tertiary-text);
	background: var(--tertiary-paper);
	border-color: var(--tertiary-border);
}

.info {
	color: var(--info-text);
	background: var(--info-paper);
	border-color: var(--info-border);
}

.success {
	color: var(--success-text);
	background: var(--success-paper);
	border-color: var(--success-border);
}

.warning {
	color: var(--warning-text);
	background: var(--warning-paper);
	border-color: var(--warning-border);
}

.error {
	color: var(--error-text);
	background: var(--error-paper);
	border-color: var(--error-border);
}
