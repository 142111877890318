:root {
	--none-border: transparent;
	--none-paper: transparent;
	--none-color: transparent;
	--none-text: transparent;
}

.dark {
	--background: #131313;
	--gap: 0.618ch;
	--opacity-dim: 0.5;
	--opacity-light: 0.3;
	--overlay-dark: rgba(19, 19, 19, 0.7686274509803922);
	--overlay-light: rgba(19, 19, 19, 0.4117647058823529);
	--transition-fast: 150ms;
	--transition-normal: 250ms;
	--transition-slow: 500ms;
	--default-border: #4d4d4d;
	--default-paper: #2e2e2e;
	--default-color: grey;
	--default-text: #fff;
	--primary-border: #546be8;
	--primary-paper: #1d39d1;
	--primary-color: #86b1ff;
	--primary-text: #fff;
	--secondary-border: #aa44f3;
	--secondary-paper: #890fe3;
	--secondary-color: #b77de2;
	--secondary-text: #fff;
	--tertiary-border: #00b2ff;
	--tertiary-paper: #0098da;
	--tertiary-color: #6cdfff;
	--tertiary-text: #fff;
	--info-border: #4d4d4d;
	--info-paper: #fff;
	--info-color: grey;
	--info-text: #2e2e2e;
	--success-border: #3ac200;
	--success-paper: #32a900;
	--success-color: #99ef61;
	--success-text: #fff;
	--warning-border: #f6b24c;
	--warning-paper: #ef960c;
	--warning-color: #ffd96f;
	--warning-text: #fff;
	--error-border: #f40b1b;
	--error-paper: #c90917;
	--error-color: #f76f79;
	--error-text: #fff;
}
